import React, {Component} from 'react'
import "./auth.css"
import axios from 'axios'
import {Redirect} from 'react-router-dom'


class AuthLogin extends Component {
   
    state = {
        username: "",
        password: "",
        token: "",
        loggedIn: false
    }


    handleSubmit = (evt) => {
        evt.preventDefault()
            let token = ""
            const user = {username:this.state.username, password: this.state.password}
            axios.post("/login", user)
            .then(response => {  
            if (response.data.token) {
                token = response.data.token
                localStorage.setItem("auth-token", token)
                this.props.handleLogin({username: this.state.username, token, isLoggedIn:true})
                this.setState({loggedIn: true})
                } 
            })
            .catch (err => {
                alert("There was an issue logging in - try again")
                this.setState({username: "", password: ""})
            })
        }




handleChange = (evt) => {
    this.setState({[evt.target.name]:evt.target.value})
}



render () {
    let pageDisplay =""

    if (!this.state.loggedIn) {
    pageDisplay =
    <form onSubmit={this.handleSubmit} className="authForm">
        <div className="labelField">
            <label htmlFor="username">Username</label>
            <input type="text" onChange={this.handleChange} name="username" value={this.state.username} className="formField" placeholder="Enter username"/>
        </div>
 
        <div className="labelField">
        <label htmlFor="password">Password</label>
        <input type="password" onChange={this.handleChange} name="password" value={this.state.password} className="formField" placeholder="Enter password"/>
        </div>
        <button type="submit" className="formButton">Submit</button>
    </form> 
    } else {
        alert("Logged in successfully!")
        return <Redirect to=
        {{pathname:"/",
            state: {loggedIn: true, username: this.state.username, token: this.state.token}
        }}
        /> 
    }

    return(
        <div className="authFormContainer">
        <h1>Login to Admin</h1>
        {pageDisplay}
        </div>
    )
}
}

export default AuthLogin