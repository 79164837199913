import React, {useState, useEffect} from 'react'
import "./projects.css"
import axios from 'axios'

const Projects2 = (props) => {
 const [projects, setProjects] = useState([])

useEffect (() => {
        axios.get("/projects")
        .then(response => 
        setProjects(response.data)) 
    }, [projects])


    let pageDisplay = ""
    if (projects.length){
    pageDisplay=projects.map(project => {
    return(
    <div key={project._id} className="project">
        <div className="titleHead">
            <h3 style={{color: props.projectHeadingColor}}>{project.projectTitle}</h3>
        </div>
        
        <div className="imageDescription">
            {project.projectPhotoPath ? 
            <img src={project.projectPhotoPath} className="image" alt="project pic"/>
            : null
            }
            <div className="description">
            {project.projectDescription}
            </div>
        </div>

        {project.projectFunding ?
            <p>Project funding: {project.projectFunding}</p>
            : null
        }
        <hr className="projectHR"/>
    </div>
    )})
    } else {
        pageDisplay = <h3>There are currently no projects</h3>
    }

      return (
        <div className="projectContainer">
            <h1 style={{color: props.projectPageHeadingColor}} className="projectHeading">Projects</h1>
            {pageDisplay}
        </div> 
     )
    }

export default Projects2