import React from 'react'
import {Redirect} from 'react-router-dom'

const AuthLogout = (props) => {
    localStorage.removeItem("auth-token")
    props.handleLogin({username: "", token: "", isLoggedIn:false})
    
        // <Redirect 
        // to="/" 
        // />
        
    return null
}

export default AuthLogout