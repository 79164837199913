import React, {Component} from 'react'
import axios from 'axios'
import "./addPaperSearchDisplay.css"
import {Redirect} from 'react-router-dom'

class DeletePaper extends Component {
    state = {
        allPapers: [],
        selectedPaper:"",
        deleted: false,
    }

componentDidMount (){
    axios.get("/updatePaper")
    .then(resp => {
        let sortedPapers = resp.data.sort(function(a, b){return b.pmid - a.pmid})
        this.setState({allPapers: sortedPapers});
      });  
}

handleSubmit = (evt) => {
    evt.preventDefault()
    axios.delete("/deletePaper",{ data: {paper: this.state.selectedPaper}})
    .then( this.setState({deleted:true}))
}

deleteMedia = () => {
    const itemForDelete = this.state.allPapers.find(item => item._id === this.state.selectedPaper)
    axios.post(`/pdfdelete/${itemForDelete.pdfPath.slice(56)}`, {id: this.state.selectedPaper})
    .then (response => {
        if (this.state.mediaType === "both") {
        this.deletePhoto(itemForDelete)
        } else {
        this.setState({deleted:true})
    }
    })
}

deletePhoto = (item) => {
    axios.post(`/photodelete/${item.photoPath.slice(56)}`, {id: item._id})
    .then(response => {
        this.setState({deleted:true})
    })
}



handleForm = (evt) => {
    if(this.state.selectedPaper){
    this.setState({selectedPaper: ""}) 
    } else {
    this.setState({selectedPaper: evt.target.value})   
    }
}



render(){ 
let paperDisplay = ""
if (!this.props.isLoggedIn) {
    alert("Must be logged in to access this page");
    paperDisplay = <Redirect to="/login" />;
  } else if (!this.state.deleted ){
    paperDisplay = this.state.allPapers.map((paper,idx) => {
   return (
    <div key={idx} className="eachPaper">
            <input type="checkbox" name="selection"  value={paper._id} onChange={this.handleForm} />
            <span className="title"> {paper.title} </span>
            <span className="authors"> {paper.authors} </span>
            <span className="year">( {paper.year} )</span>
            <span className="journal" style={{textTransform:"capitalize"}}> {paper.journal}, </span>
            <span className="volume"> {paper.volume}: </span>
            <span className="pages"> {paper.pages}</span>
            {paper.pmid &&
            <span className="pmid">, PMID: <a href= {`https://www.ncbi.nlm.nih.gov/pubmed/${paper.pmid}`} target="_blank" rel="noopener noreferrer"> {paper.pmid} </a></span>
            }      
   </div>
   )
})
    } else if (this.state.deleted) {
    alert("Publication deleted!")
    paperDisplay = <Redirect to="/admin/home" />;
  } 

    return (
        
    
        <div className="addPaperSearchDisplayContainer">
        <div className="addPaperSearchFormHeading">
            <h1 className="addPaperSearchFormHeadingH1">Delete A Paper</h1>
            <h3>Check the ones that you want to delete from your library then click "Submit"</h3>
        </div> 
                <form onSubmit={this.handleSubmit}>
                {paperDisplay}
                <button type="submit" className="selectButton">Submit</button>
                </form>
        </div>
  
)
} 
}

export default DeletePaper