import React from "react";
import "./Home.css";

const Home = (props) => {
  let styleHead = {};
  if (props.labdetails.labNameColor)
    styleHead.color = props.labdetails.labNameColor;
  if (props.labdetails.labNameSize)
    styleHead.fontSize = `${props.labdetails.labNameSize}rem`;

  let styleSubHead = {};
  if (props.labdetails.labSubheadingColor)
    styleSubHead.color = props.labdetails.labSubheadingColor;
  if (props.labdetails.labSubheadingSize)
    styleSubHead.fontSize = `${props.labdetails.labSubheadingSize}rem`;

  let styleMinorHead = {};
  if (props.labdetails.labMinorheadingColor)
    styleMinorHead.color = props.labdetails.labMinorheadingColor;
  if (props.labdetails.labSubheadingSize)
    styleMinorHead.fontSize = `${props.labdetails.labMinorheadingSize}rem`;

  let styleDescriptBkd = {};
  if (props.labdetails.labDescriptionBkdColor)
    styleDescriptBkd.backgroundColor = props.labdetails.labDescriptionBkdColor;

  let styleDescriptText = {};
  if (props.labdetails.labDescriptionColor)
    styleDescriptText.color = props.labdetails.labDescriptionColor;

  return (
    <div className="homeContainer">
      <div className="banner">
        <div className="titles">
          <h1 className="bannerTitle" style={styleHead}>
            {props.labdetails.labName}
          </h1>
          {props.labdetails.labSubheading && (
            <p className="bannerSubheading" style={styleSubHead}>
              {props.labdetails.labSubheading}
            </p>
          )}
          {props.labdetails.labMinorheading && (
            <p className="bannerMinorheading" style={styleMinorHead}>
              {props.labdetails.labMinorheading}
            </p>
          )}
        </div>
        <div className="creditContainer">
          <p className="credit">
            Image courtesy of Georgia Atkin-Smith & Ivan Poon, La Trobe
            University
          </p>
        </div>
        <img
          className="bannerPhoto"
          src="/cdslab_1.png"
          alt="team members in lab"
        />
        <div className="labDescription">
          <p className="labDescriptionText" style={styleDescriptText}>
            {props.labdetails.labDescription}
          </p>
        </div>
        <div className="blogContainer">
          <h1 className="blogHeading" style={{ color: props.blogHeadingColor }}>
            Announcements
          </h1>
        </div>
        {/* <div className="bcl2sitecontainer">
          <img
            className="bcl2websiteImage"
            src="logoBorderWhiteBlackborderBluetextNobold.png"
            alt="wordcloud"
          />
          <p className="bcl2websiteText" style={styleDescriptText}>
            Do you need editing of any your scientific documents? We provide
            professional editing services for papers, theses, grant applications
            and other scientific documents at highly competetive rates. Find us
            at{" "}
            <a
              href="https://www.expertmanuscriptediting.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Expert Manuscript Editing
            </a>
            .
          </p>
        </div> */}

        <div className="bcl2sitecontainer">
          <img
            className="bcl2websiteImage"
            src="/Asset1favicon.png"
            alt="bcl2 website"
          />
          <p className="bcl2websiteText" style={styleDescriptText}>
            Check out our website devoted to BCL-2 research. It has the latest
            papers updated daily, history, labs worldwide, classic papers and
            other resources such as conferences, journals and societies. Find it
            at{" "}
            <a
              href="https://www.bcl2.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              bcl2.co
            </a>
            .
          </p>
        </div>

        <div className="bcl2sitecontainer">
          <img
            className="bcl2websiteImage"
            src="/wordcloud_rect.png"
            alt="wordcloud"
          />
          <p className="bcl2websiteText" style={styleDescriptText}>
            Want to create beautiful, fully custoimisable WordClouds like the
            ones you see on this page? Visit our new WordCloud generator! It has
            multiple approaches to creating your wordlists on which your cloud
            will be generated including a unique tool to search your
            publications to identify and assign value to specific keywors you
            might want to include.Find it at{" "}
            <a
              href="https://wordcloudmaker.herokuapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
