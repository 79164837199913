import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import './Toolbar.css'


class  Toolbar extends Component {
   state = {
       swidth: window.innerwidth
   }

   componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  handleLogout = () => {
    this.props.handleLogout()
    alert("You have been successfully logged out!")
  }



    render(){
        let bar = ""
        if(this.state.width > 830) {
            bar = 
            <nav className="nav" style={{backgroundColor: this.props.menuBarColor || "black"}}>
            <div>
                <Link to="/" className="link" style={{color: this.props.menuBarTextColor || "white"}}>Home</Link>
                <Link to="/pubsgallery" className="link" style={{color: this.props.menuBarTextColor || "white"}}>Publications</Link>
                <Link to="/team" className="link" style={{color: this.props.menuBarTextColor || "white"}}>People</Link>
                <Link to="/prts" className="link" style={{color: this.props.menuBarTextColor || "white"}}>Projects</Link>
                <Link to="/photos" className="link" style={{color: this.props.menuBarTextColor || "white"}}>Photos</Link>
            </div>

          <div>
            {this.props.isLoggedIn ?
            <>
                <Link onClick={this.handleLogout} className="link" to="/" style={{color: this.props.menuBarTextColor}}>Logout</Link>
                {/* <p className="link" style={{color: this.props.menuBarTextColor}} onClick=>Logout</p> */}
                <Link to="/lbdocs" className="link" style={{color: this.props.menuBarTextColor}}>Documents</Link>
                <Link to="/admin/home" className="link" style={{color: this.props.menuBarTextColor}}>Admin</Link>
            </>
            :
              <Link to="/login" className="link" style={{color: this.props.menuBarTextColor}}>Login</Link>
            }
                <Link to="/contact" className="link" style={{color: this.props.menuBarTextColor}}>Contact</Link>
                </div>
          </nav>
          } else {
        bar =  
        <nav className="nav" style={{backgroundColor: this.props.menuBarColor || "black"}}>
        <Menu customBurgerIcon={ <img src={"https://upload.wikimedia.org/wikipedia/commons/5/59/Hamburger_icon_white.svg" } alt="burger menu icon"/> }>
        <a id="home" className="menu-item" href="/">Home</a>
        <a id="publications" className="menu-item" href="/pubsgallery">Publications</a>
        <a id="people" className="menu-item" href="/team">People</a>
        <a id="prts" className="menu-item" href="/prts">Projects</a>
        <a id="photos" className="menu-item" href="/photos">Photos</a>
        {/* <a id="lbdocs" className="menu-item" href="/lbdocs">Documents</a>
        <a id="admin" className="menu-item" href="/admin/home">Admin</a> */}
        <a id="contact" className="menu-item" href="/contact">Contact</a>
        {/* <a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a> */}
      </Menu>
      </nav>
        }

    return (
        bar
      );
  }
}
export default Toolbar;