import React, { Component } from 'react';
import axios from 'axios-https-proxy-fix'; 
import PaperDisplay from './PaperDisplay'
import {Route, Switch} from 'react-router-dom'
import Toolbar from './Toolbar2'
import Home from './Home'
import AuthLogin from './AuthLogin'
import AuthRegister from './AuthRegister'
import AuthLogout from './AuthLogout'
import AdminHomeForm from './AdminHomeForm'
import AddPaperSearch from './AddPaperSearch'
import AddPaperManual from './AddPaperManual'
import UpdatePaper from './UpdatePaper'
import DeletePaper from './DeletePaper'
import AddMedia from './AddMedia'
import DeleteMedia from './DeleteMedia'
import CreateSite from "./CreateSite"
import EditSite from "./EditSite"
import CreateBlogPost from "./CreateBlogPost"
import UpdateBlogPost from "./UpdateBlogPost"
import DeleteBlogPost from "./DeleteBlogPost"
import Blogs from './Blogs'
import CreateTeamMember from "./CreateTeamMember"
import EditTeamMember from "./EditTeamMember"
import DeleteTeamMember from "./DeleteTeamMember"
import TeamMembers from "./TeamMembers"
import CreateProject from "./CreateProject"
import EditProject from "./EditProject"
import DeleteProject from "./DeleteProject"
// import Projects from "./Projects"
import Projects2 from "./Projects2"
import CreateLabDocument from "./CreateLabDocument"
import EditLabDocument from "./EditLabDocument"
import DeleteLabDocument from "./DeleteLabDocument"
import LabDocuments from "./LabDocuments"
import LabPhotos from "./LabPhotos"
import CreateLabPhoto from "./CreateLabPhoto"
import EditLabPhoto from "./EditLabPhoto"
import DeleteLabPhoto from "./DeleteLabPhoto"
import Contact from "./Contact"
import "./App.css"


class App extends Component {
  state = {
    id: "",
    labName:"",
    labDescription:"",
    labSubheading:"",
    labMinorheading:"",
    labNameColor: "",
    labNameSize: "",
    labSubheadingColor: "",
    labSubheadingSize: "",
    labMinorheadingColor:"",
    labMinorheadingSize:"",
    menuBarColor: "",
    menuBarTextColor: "",
    labDescriptionColor: "",
    labDescriptionBkdColor: "",
    blogHeading:"",
    blogHeadingColor:"",
    blogPostHeadingColor:"",
    publicationPageHeadingColor:"",
    projectPageHeadingColor:"",
    projectHeadingColor:"",
    peoplePageHeadingColor:"",
    peopleHeadingColor:"",
    photoPageHeadingColor:"",
    photoHeadingColor:"",
    themeColor:"",
    siteDataError: false,
    currentPage: 1,
    isLoggedIn: false,
    token: "",
    username: ""
  }
 
componentDidMount (props) {
  let username = ""
  let token = localStorage.getItem("auth-token")
  if (token === null){
    localStorage.setItem("auth-token", "")
    token = ""
  }

  axios.post("/tokenIsValid", null, {headers: {"x-auth-token": token}})
  .then(response => {
    if (response.data){
      axios.get("/getUser", {headers: {'x-auth-token': token}})
      .then(response2 => {
        this.setState({token, username: response2.data.username, isLoggedIn: true })
      })
  }
  })
  .then(this.setSiteInfo(token, username))
}

setSiteInfo = (token, username) => {
axios.get("/siteInfo")
        .then(response => {
    
          if (response.data[0].labName){

      let {_id, labName, labNameSize, labNameColor, labDescription, labSubheading, labSubheadingSize, labSubheadingColor, labMinorheading, labMinorheadingSize, labMinorheadingColor, menuBarColor, menuBarTextColor,labDescriptionColor, labDescriptionBkdColor, blogHeading, blogHeadingColor, blogPostHeadingColor, publicationPageHeadingColor, projectPageHeadingColor,projectHeadingColor, peoplePageHeadingColor,peopleHeadingColor, photoPageHeadingColor, photoHeadingColor, themeColor} = response.data[0]
      
      this.setState({labName, labNameSize, labNameColor, labDescription, labSubheading, labSubheadingSize,labSubheadingColor, menuBarColor, menuBarTextColor, labDescriptionColor, labDescriptionBkdColor,  labMinorheading, labMinorheadingSize, labMinorheadingColor,  blogHeading, blogHeadingColor, blogPostHeadingColor, publicationPageHeadingColor,projectPageHeadingColor, projectHeadingColor, peoplePageHeadingColor,peopleHeadingColor, photoPageHeadingColor, photoHeadingColor,themeColor, id: _id, username, token})

  } else {
      this.setState({siteDataError: true})
  }
})
}


siteCreated = () => {
  axios.get("/siteInfo")
  .then(response => {
    let {labName, labDescription, labSubheading, labMinorheading} = response.data[0]
    this.setState({labName, labDescription, labSubheading, labMinorheading, siteDataError: false})
})
}

siteEdited = () => {
  axios.get("/siteInfo")
  .then(response => {
    let {labName, labDescription, labSubheading, labMinorheading,labNameColor,labNameSize,labSubheadingColor, labSubheadingSize, menuBarColor, menuBarTextColor,labDescriptionColor, labDescriptionBkdColor, labMinorheadingColor, labMinorheadingSize, blogHeading, blogHeadingColor, blogPostHeadingColor, projectPageHeadingColor, projectHeadingColor, peoplePageHeadingColor,peopleHeadingColor, photoPageHeadingColor, photoHeadingColor,themeColor} = response.data[0]
    
    this.setState({labName, labDescription, labSubheading,labMinorheading, labNameColor,labNameSize,labSubheadingColor, labSubheadingSize, labMinorheadingColor, labMinorheadingSize, menuBarColor, menuBarTextColor,labDescriptionColor,labDescriptionBkdColor, blogHeading,
      blogHeadingColor, blogPostHeadingColor, projectPageHeadingColor, projectHeadingColor, peoplePageHeadingColor,peopleHeadingColor, photoPageHeadingColor, photoHeadingColor,themeColor, siteDataError: false})
})
}

handleLogin = (user) => {
  const {username, isLoggedIn} = user
  this.setState({token: localStorage.getItem("auth-token"), username, isLoggedIn})
}

handleLogout = () => {
  localStorage.removeItem("auth-token")
  this.setState({token: null, username: "", isLoggedIn: false})
}

render() {
  let {currentPage, siteDataError} = this.state

    return (
    <div>

        <Toolbar 
        menuBarColor = {this.state.menuBarColor}
        menuBarTextColor = {this.state.menuBarTextColor}
        isLoggedIn={this.state.isLoggedIn}
        handleLogout={this.handleLogout}
        />
        {siteDataError && <h2>There was an error loading the page.</h2>}

        <Switch>
          <>
          <Route exact path="/" render= {() => 
          
            <>
            <div className="frontPage">
            <Home 
            blogHeadingColor={this.state.blogHeadingColor}
            labdetails={this.state}
            />
             
            <Blogs
            blogHeading={this.state.blogHeading}
            blogHeadingColor={this.state.blogHeadingColor}
            blogPostHeadingColor={this.state.blogPostHeadingColor}
            />
            </div>
           </>
            }
            />

            <Route exact path="/prts" render={()=>
            <Projects2
            projectPageHeadingColor = {this.state.projectPageHeadingColor}
            projectHeadingColor = {this.state.projectHeadingColor}
            />
            }
            />

            <Route exact path="/lbdocs" render={(routeProps)=>
            <LabDocuments
            {...routeProps}
            />
            }
            />
        
            <Route exact path="/pubsgallery" render={()=>
            <PaperDisplay
            publicationPageHeadingColor={this.state.publicationPageHeadingColor}
            currentPage={currentPage}/>
            }/>
            
            <Route exact path="/admin/home" render={(routeProps)=>
            <AdminHomeForm
            {...routeProps}
            isLoggedIn= {this.state.isLoggedIn}
            token={this.state.token}
            handleLogin ={this.handleLogin}
            username={this.state.username}
            publicationPageHeadingColor={this.state.publicationPageHeadingColor}
            />
            }
            /> 

          <Route exact path="/adminhome/createsite" render={(routeProps)=>
            <CreateSite
            {...routeProps}
            siteCreated = {this.siteCreated}
            token={this.state.token}
            isLoggedIn={this.state.isLoggedIn}
            handleLogin={this.handleLogin}
            />
            }
            /> 

          <Route exact path="/adminhome/editsite" render={()=>
            <EditSite
            isLoggedIn= {this.state.isLoggedIn}
            token={this.state.token}
            siteInfo = {this.state}
            siteEdited = {this.siteEdited}
            />
            }
            />

          <Route exact path="/adminhome/createBlogPost" render={()=>
            <CreateBlogPost
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 
            
          <Route exact path="/adminhome/updateBlogPost" render={(routeProps)=>
            <UpdateBlogPost
            {...routeProps}
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 

          <Route exact path="/adminhome/deleteBlogPost" render={(routeProps)=>
            <DeleteBlogPost
            {...routeProps}
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 
            
          <Route exact path="/adminhome/addpublicationsearch" render={()=>
            <AddPaperSearch
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 

          <Route exact path="/adminhome/addpublicationmanual" render={()=>
            <AddPaperManual
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 

          <Route exact path="/adminhome/editpublication" render={()=>
            <UpdatePaper
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 
            
          <Route exact path="/adminhome/deletepublication" render={()=>
            <DeletePaper
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 
            
          <Route exact path="/adminhome/addmedia" render={(routeProps)=>
            <AddMedia
            {...routeProps}
            />
            }
            />

          <Route exact path="/adminhome/deletemedia" render={(routeProps)=>
            <DeleteMedia
            {...routeProps}
            />
            }
            />

          <Route exact path="/team" render={()=>
            <TeamMembers
            peoplePageHeadingColor={this.state.peoplePageHeadingColor}
            peopleHeadingColor={this.state.peopleHeadingColor}
            />
            }
            /> 

          <Route exact path="/adminhome/createmember" render={(routeProps)=>
            <CreateTeamMember
            {...routeProps}
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 

          <Route exact path="/adminhome/editmember" render={(routeProps)=>
            <EditTeamMember
            {...routeProps}
            token={this.state.token}
            isLoggedIn={this.state.isLoggedIn}
            />
            }
            /> 
            
          <Route exact path="/adminhome/deletemember" render={(routeProps)=>
            <DeleteTeamMember
            {...routeProps}
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 

          <Route exact path="/adminhome/createproject" render={()=>
            <CreateProject
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            
            }
            /> 

          <Route exact path="/adminhome/editproject" render={(routeProps)=>
            <EditProject
            {...routeProps}
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 
            
          <Route exact path="/adminhome/deleteproject" render={(routeProps)=>
            <DeleteProject
            {...routeProps}
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 

          <Route exact path="/adminhome/createlabdocument" render={()=>
            <CreateLabDocument
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 

          <Route exact path="/adminhome/editlabdocument" render={(routeProps)=>
            <EditLabDocument
            {...routeProps}
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 
            
          <Route exact path="/adminhome/deletelabdocument" render={(routeProps)=>
            <DeleteLabDocument
            {...routeProps}
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 

          <Route exact path="/photos" render={(routeProps)=>
            <LabPhotos
            {...routeProps}
            photoPageHeadingColor={this.state.photoPageHeadingColor}
            photoHeadingColor={this.state.photoHeadingColor}
            />
            }
            />

          <Route exact path="/adminhome/createlabphoto" render={()=>
            <CreateLabPhoto
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 

          <Route exact path="/adminhome/editlabphoto" render={(routeProps)=>
            <EditLabPhoto
            {...routeProps}
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 
            
          <Route exact path="/adminhome/deletelabphoto" render={(routeProps)=>
            <DeleteLabPhoto
            {...routeProps}
            isLoggedIn={this.state.isLoggedIn}
            token={this.state.token}
            />
            }
            /> 

          <Route exact path="/contact" render={(routeProps)=>
            <Contact
            {...routeProps}
            photoPageHeadingColor={this.state.photoPageHeadingColor}
            />
          }
          /> 

            <Route exact path="/register" render={()=>
            <AuthRegister
            />
            }
            />

            <Route exact path="/login" render={(routeProps)=>
            <AuthLogin
            {...routeProps}
            handleLogin={this.handleLogin}
            isLoggedIn={this.state.isLoggedIn}
            />
            }
            />

            <Route exact path="/logout" render={(routeProps)=>
              
              <AuthLogout
              handleLogin={this.handleLogin}
              />
            }
            />
          </> 
        </Switch> 
      </div>
      // {/* </UserContext.Provider> */}
    );
  }
}

export default App