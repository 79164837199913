import React, {Component} from 'react'
import "./auth.css"
import axios from 'axios'

class AuthRegister extends Component {
    state = {
        username: "",
        email: "",
        password: "",
        loggedIn: false
    }


handleSubmit = (evt) => {
    evt.preventDefault()
    const user = {username:this.state.username, email:this.state.email, password: this.state.password}
    axios.post("/register", user)
    .then(response => console.log("registered"))
}

handleChange = (evt) => {
    this.setState({[evt.target.name]:evt.target.value})
}



render () {

    const form =
    <form onSubmit={this.handleSubmit} className="authForm">
        <div className="labelField">
            <label htmlFor="username">Username</label>
            <input type="text" onChange={this.handleChange} name="username" value={this.state.username} className="formField" placeholder="Enter username"/>
        </div>
        <div className="labelField">
        <label htmlFor="email">Email</label>
        <input type="email" onChange={this.handleChange} name="email" value={this.state.email} className="formField" placeholder="Enter email"/>
        </div>
        <div className="labelField">
        <label htmlFor="password">Password</label>
        <input type="password" onChange={this.handleChange} name="password" value={this.state.password} className="formField" placeholder="Enter password"/>
        </div>
        <button type="submit" className="formButton">Submit</button>
    </form> 
   

    return(
        <div className="authFormContainer">
        <h1>Register User</h1>
        {form}
        </div>
    )
}
}

export default AuthRegister