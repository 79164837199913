import React, { Component } from "react";
import axios from "axios";
import {Redirect} from 'react-router-dom'
import "./addPaperManual.css";

class UpdatePaperForm extends Component {
  state = {
    id: "",
    title: "",
    authors: "",
    journal: "",
    year: "",
    volume: "",
    pages: "",
    pmid: "",
    type: "",
    comments: "",
    updated: false,
    loginError: false
  };

  componentDidMount() {
    this.setState({
      id: this.props.paper._id,
      title: this.props.paper.title,
      authors: this.props.paper.authors,
      journal: this.props.paper.journal,
      year: this.props.paper.year,
      volume: this.props.paper.volume,
      pages: this.props.paper.pages,
      pmid: this.props.paper.pmid,
      type: this.props.paper.type,
      comments: this.props.paper.comments,
      loginError: false
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    let paper = "";

    paper = this.state;
    axios
      .put("/updatePaper", { paper }, {headers: {"x-auth-token": this.props.token}})
      .then(this.setState({ updated: true }))
      .then(alert("Paper details successfully updated!"))
      .catch(err => {
        alert("Must login")
        this.setState({loginError: true})
        })
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      id,
      title,
      authors,
      journal,
      year,
      volume,
      pages,
      pmid,
      comments,
      type,
    } = this.state;
    let pageDisplay = "";
    if (!this.state.updated && !this.state.loginErrir) {
      pageDisplay = (
        <div className="addPaperManualContainer">
          <h1 className="addPaperManualFormHeading">
            Update or edit your paper
          </h1>
          <form onSubmit={this.handleFormSubmit} className="addPaperManualForm">
            <input type="hidden" name="id" value={id} />

            <label htmlFor="title" className="formLabel">
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={title}
              onChange={this.handleChange}
              className="manualSearchInput"
            />

            <label htmlFor="authors" className="formLabel">
              Authors
            </label>
            <input
              type="text"
              id="authors"
              name="authors"
              value={authors}
              onChange={this.handleChange}
              className="manualSearchInput"
            />

            <label htmlFor="journal" className="formLabel">
              Journal
            </label>
            <input
              type="text"
              id="authors"
              name="journal"
              value={journal}
              onChange={this.handleChange}
              className="manualSearchInput"
            />

            <label htmlFor="year" className="formLabel">
              Year
            </label>
            <input
              type="number"
              id="year"
              name="year"
              value={year}
              onChange={this.handleChange}
              className="manualSearchInput"
            />

            <label htmlFor="volume" className="formLabel">
              Volume
            </label>
            <input
              type="number"
              id="volume"
              name="volume"
              value={volume}
              className="manualSearchInput"
              onChange={this.handleChange}
            />

            <label htmlFor="pages" className="formLabel">
              Pages
            </label>
            <input
              type="text"
              id="pages"
              name="pages"
              value={pages}
              onChange={this.handleChange}
              className="manualSearchInput"
            />

            <label htmlFor="PMID" className="formLabel">
              PMID
            </label>
            <input
              type="text"
              id="pmid"
              name="pmid"
              value={pmid}
              onChange={this.handleChange}
              className="manualSearchInput"
            />

            <div className="typeDropDown">
              <label htmlFor="type" className="typeLabel">
                Type
              </label>
              <select
                id="type"
                name="type"
                value={type}
                onChange={this.handleChange}
              >
                <option value="primary" defaultValue>
                  Primary Journal
                </option>
                <option value="review">Review Article</option>
                <option value="book">Book</option>
                <option value="chapter">Book Chapter</option>
                <option value="letter">Letter</option>
                <option value="commentary">Commentary</option>
                <option value="editorial">Editorial</option>
                <option value="magazine">Magazine Article</option>
                <option value="other">Other</option>
              </select>
            </div>
            <label className="formLabel" htmlFor="comments">
              Comments
            </label>
            <textarea
              name="comments"
              rows="4"
              cols="65"
              onChange={this.handleChange}
              className="commentTextArea"
              placeholder="Enter comments about the paper (e.g. joint first/last authors, commentaries, journal cover etc)"
              value={comments}
            />

            <input
              type="submit"
              value="Submit"
              className="manualSearchButton"
            />
          </form>
        </div>
      );
    } else if (this.state.updated){
        pageDisplay =
        <Redirect to="/admin/home" />
    } else if (this.state.loginError){
        pageDisplay =
        <Redirect to="/login" />
    }

    return <div>{pageDisplay}</div>;
  }
}

export default UpdatePaperForm;
