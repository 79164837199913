import React from 'react'
import "./contact.css"


const Contact = (props) => {
    

 return (
    <div className="contactContainer">
        <h1 className="contactHeading" style={{color: props.photoPageHeadingColor}}>Contact Us!</h1>
        <p className="contactText">We have decades experience in apoptosis and autophagy research. Over the years we have developed or accumalated many useful tools, protocols and reagents including cell lines with various genes KO, transgenic and KO mice, antibodies, recombinant proteins, peptides, drugs etc. 
        </p>
        <p className="contactText">If you are interested in collaborating with us, we are very open to working with both academic and industry partners. 
        </p>
        <p className="contactText">Contact us at doug.fairlie@onjcri.org.au / erinnna.lee@onjcri.org.au if you want to discuss further.</p>



    </div>
 )
}

export default Contact